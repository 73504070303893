export interface VarEventsListenerOptions {
  url: string;
  connect: boolean;
  debug: boolean;
  reconnectSecs: number;
  matchIds?: string[];
}

export type VarEventsListenerEventType = 'varEvent';

class VarEventsListener {
  private socket: WebSocket | null = null;
  private socketUrl: string = 'wss://var.futbalsfz.sk/varws';
  private explicitClose: boolean = false;
  private reconnectTimeout: NodeJS.Timeout | null = null;
  private options: VarEventsListenerOptions;
  private defaultOptions: VarEventsListenerOptions = {
    url: 'wss://var.futbalsfz.sk/varws',
    reconnectSecs: 2,
    debug: false,
    connect: true,
  };

  // eslint-disable-next-line no-unused-vars
  private eventHandlers: { [key in VarEventsListenerEventType]?: any[] } = {};

  constructor(options: Partial<VarEventsListenerOptions> = {}) {
    this.options = { ...this.defaultOptions, ...options };
    if (options.connect) {
      this.connect();
    }
  }

  log(...args: any) {
    if (this.options.debug) {
      console.info(...args);
    }
  }

  connect() {
    if (this.socket) {
      this.log('Socket already active');
      return this.socket;
    }
    this.explicitClose = false;
    this.log(`Creating websocket ${this.options.url}...`);
    this.socket = new WebSocket(this.socketUrl);
    // doplnime handlery
    this.socket.addEventListener('open', () => {
      this.log(
        `Connected to websocket ${this.options.url}`,
        this.socket?.readyState
      );
    });
    this.socket.addEventListener('close', () => {
      this.log(`Socket closed`);
      this.socket = null;
      // znovuotvorime spojenie ak padlo
      if (!this.explicitClose) {
        this.log(`Reconnecting in ${this.options.reconnectSecs} seconds...`);
        this.reconnectTimeout = setTimeout(() => {
          this.connect();
        }, this.options.reconnectSecs * 1000);
      }
    });
    this.socket.addEventListener('error', (e) => {
      console.error('VarEventsListener.Socket.Error', e);
    });
    this.socket.addEventListener('message', (message) => {
      if (!this.options.url.startsWith(message.origin)) {
        this.log(`Invalid message origin ${message.origin}`);
        return;
      }
      // skontrolujeme message.data - mal by to byt JSON
      try {
        const jsonData = JSON.parse(message.data);
        const { matchId, datetime, ...varEvent } = jsonData;
        if (matchId && datetime) {
          if (
            !this.options.matchIds ||
            this.options.matchIds.includes(matchId)
          ) {
            this.eventHandlers['varEvent']?.forEach((cb) =>
              cb(matchId, { ...varEvent, datetime })
            );
          }
        } else {
          this.log('Unknown message format', jsonData);
        }
      } catch (e) {
        this.log('Invalid message received', message.data, e);
      }
    });
  }

  close() {
    this.explicitClose = true;
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
    this.log('User closed socket');
    this.socket?.close();
  }

  on(
    event: VarEventsListenerEventType,
    // eslint-disable-next-line no-unused-vars
    handler: (matchId: string, event: any) => void
  ) {
    if (!(event in this.eventHandlers)) {
      this.eventHandlers[event] = [];
    }
    this.eventHandlers[event]!.push(handler);
  }

  off(event: VarEventsListenerEventType, handler?: any) {
    if (handler) {
      const handlerIndex = this.eventHandlers[event]?.indexOf(handler);
      if (handlerIndex && handlerIndex >= 0) {
        this.eventHandlers[event]?.splice(handlerIndex, 1);
      }
    } else {
      delete this.eventHandlers[event];
    }
  }
}

export default VarEventsListener;
